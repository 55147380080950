.navbar {
  left: 0;
  right: 0;
  height: 6rem;
  z-index: 100;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  font-weight: var(--light-300);
  color: var(--gray-darker-inactive);
}

.navbar ul {
  display: flex;
  opacity: unset;
  list-style: none;
  border-radius: 2rem;
  padding: 0.8rem 1.8rem;
  -webkit-backdrop-filter: blur(8px); /* Safari 9+ */
  backdrop-filter: blur(8px); /* Chrome and Opera */
  background-color: var(--gray-navbar-background);
}

.navbar li {
  /* opacity: unset; */
  cursor: pointer;
  font-size: 2rem;
  transition: color 1.5s ease;
}

.navbar li:hover {
  color: var(--orange);
}

.navbar .navbar__active {
  color: var(--orange);
}

.navbar_show {
  display: none;
}

.projects {
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  background-color: var(--black);
}

.container {
  max-width: var(--content-width);
}

.imageContainer {
  flex: 3;
}

.imageContainer img {
  width: 100%;
  border: 2px solid var(--gray-darker);
}

.textContainer {
  flex: 2;
}

.textContainer p {
  color: var(--gray-lighter);
  font-weight: var(--thin-100);
}

.topWrapper {
  display: flex;
  align-items: flex-end;
}

.topWrapper h2 {
  color: var(--gray-lighter);
  font-weight: var(--semiBold-600);
}

.topWrapper a {
  text-decoration: none;
  color: var(--gray-darker);
  font-weight: var(--light-300);
}

.topWrapper a:hover {
  color: var(--orange);
  transition: color 0.5s ease-in-out;
}

/* SMARTPHONES - SHORTER SCREENS (PORTRAIT) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
  .projects {
    padding-top: var(--padding-l);
    padding-left: var(--padding-s);
    padding-right: var(--padding-s);
    padding-bottom: var(--padding-s);
  }

  .container {
    height: fit-content;
    padding-bottom: 2rem;
    flex-direction: column;
  }

  .container:last-child {
    padding-bottom: 0;
  }

  .imageContainer {
    padding-right: 0;
  }

  .imageContainer img {
    border-radius: 1.2rem;
  }

  .topWrapper h2 {
    font-size: 1.8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .textContainer p {
    font-size: 1.6rem;
  }

  .topWrapper a {
    font-size: 1.4rem;
    padding-left: 1rem;
    padding-bottom: 0.6rem;
  }
}

/* SMARTPHONES - SHORTER SCREENS (LANDSCAPE) */
@media only screen and (min-width: 665px) and (orientation: landscape) {
  .projects {
    padding-top: var(--padding-l);
    padding-left: var(--padding-s);
    padding-right: var(--padding-s);
    padding-bottom: var(--padding-s);
  }

  .container {
    height: fit-content;
    padding-bottom: 2rem;
    flex-direction: column;
  }

  .container:last-child {
    padding-bottom: 0;
  }

  .imageContainer {
    padding-right: 0;
  }
  .imageContainer img {
    border-radius: 1.2rem;
  }

  .topWrapper h2 {
    font-size: 1.8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .textContainer p {
    font-size: 1.6rem;
  }

  .topWrapper a {
    font-size: 1.4rem;
    padding-left: 1rem;
    padding-bottom: 0.6rem;
  }
}

/* SMARTPHONES - TALLER SCREENS (PORTRAIT) */
@media only screen and (min-width: 390px) and (orientation: portrait) {
  /* not needed */
}

/* SMARTPHONES - TALLER SCREENS (LANDSCAPE) */
@media only screen and (min-width: 843px) and (orientation: landscape) {
  /* not needed */
}

/* IPAD MINI (PORTRAIT) */
@media only screen and (min-width: 767px) and (orientation: portrait) {
  .projects {
    padding-top: var(--padding-l);
    padding-left: var(--padding-m);
    padding-right: var(--padding-m);
    padding-bottom: var(--padding-m);
  }

  .container {
    height: fit-content;
    padding-bottom: 2rem;
    flex-direction: column;
  }

  .container:last-child {
    padding-bottom: 0;
  }

  .imageContainer {
    padding-right: 0;
  }

  .imageContainer img {
    border-radius: 1.4rem;
  }

  .topWrapper h2 {
    font-size: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .textContainer p {
    font-size: 1.8rem;
  }

  .topWrapper a {
    font-size: 1.6rem;
    padding-left: 1rem;
    padding-bottom: 0.6rem;
  }
}

/* IPAD MINI (LANDSCAPE) */
@media only screen and (min-width: 1023px) and (orientation: landscape) {
  .projects {
    padding-top: var(--padding-l);
    padding-left: var(--padding-m);
    padding-right: var(--padding-m);
    padding-bottom: var(--padding-m);
  }

  .imageContainer img {
    border-radius: 1.4rem;
  }

  .topWrapper h2 {
    font-size: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .textContainer p {
    font-size: 1.8rem;
  }

  .topWrapper a {
    font-size: 1.6rem;
  }
}

/* IPAD AIR (PORTRAIT) */
@media only screen and (min-width: 769px) and (orientation: portrait) {
  /* not needed */
}

/* IPAD AIR (LANDSCAPE) */
@media only screen and (min-width: 1179px) and (orientation: landscape) {
  .container {
    display: flex;
    min-height: 100dvh;
    align-items: center;
    flex-direction: row;
  }

  .imageContainer img {
    border-radius: 1.8rem;
  }

  .textContainer {
    margin-left: 1rem;
  }
}

/* IPAD PRO (PORTRAIT) */
@media only screen and (min-width: 1023px) and (orientation: portrait) {
  /* not needed */
}

/* IPAD PRO and MACBOOK PRO 14" (LANDSCAPE) */
@media only screen and (min-width: 1365px) and (orientation: landscape) {
  /* not needed */
}

/* MONITORS (LANDSCAPE) */
@media only screen and (min-width: 1520px) and (orientation: landscape) {
  /* not needed */
}

.skills {
  z-index: 2;
  display: flex;
  min-height: 100dvh;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
}

.container {
  columns: 4;
  max-width: var(--content-width);
}

.container ul {
  list-style: none;
  color: var(--gray-lighter);
}

.container ul li:first-child {
  font-weight: var(--semiBold-600);
}

.container li {
  font-weight: var(--thin-100);
}

.skills a {
  text-decoration: none;
  color: var(--gray-lighter);
  transition: color 0.5s ease, stroke 0.5s ease, fill 0.5s ease;
}

.container svg {
  margin: 0;
  padding: 0;
  position: relative;
  fill: var(--gray-dark);
  stroke: var(--gray-dark);
  transition: color 0.5s ease, stroke 0.5s ease, fill 0.5s ease;
}

.container .link:hover a,
.container .link:hover svg {
  fill: var(--orange);
  color: var(--orange);
  stroke: var(--orange);
  transition: color 0.5s ease, stroke 0.5s ease, fill 0.5s ease;
}

/* SMARTPHONES - SHORTER SCREENS (PORTRAIT) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
  .skills {
    align-items: flex-end;
    min-height: fit-content;
    justify-content: flex-start;
    padding-top: var(--padding-l);
    padding-left: var(--padding-s);
    padding-right: var(--padding-s);
    padding-bottom: var(--padding-s);
  }
  .container {
    columns: 1;
  }
  .container ul li:first-child {
    font-size: 1.8rem;
  }

  .container ul {
    padding-bottom: 1.4rem;
  }

  .container ul:last-child {
    padding-bottom: 0;
  }

  .container li {
    font-size: 1.6rem;
    padding-top: 1.2rem;
  }
  .topLi {
    padding-top: 0rem !important;
  }

  .skills a {
    font-size: 1.6rem;
  }

  .container svg {
    top: 0.1rem;
    height: 1.5rem;
    margin-left: 0.4rem;
  }
}

/* SMARTPHONES - SHORTER SCREENS (LANDSCAPE) */
@media only screen and (min-width: 665px) and (orientation: landscape) {
  .skills {
    padding-top: var(--padding-l);
    padding-left: var(--padding-s);
    padding-right: var(--padding-s);
    padding-bottom: var(--padding-s);
  }

  .container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .container ul li:first-child {
    font-size: 1.8rem;
    padding-bottom: 0.2rem;
  }

  .container ul {
    padding-bottom: 1.6rem;
  }

  .container ul:nth-child(3),
  .container ul:last-child {
    padding-bottom: 0;
  }

  .container li {
    font-size: 1.6rem;
    padding-top: 0.7rem;
  }

  .topLi {
    padding-top: 0rem !important;
  }

  .topLi2 {
    padding-top: 0rem !important;
  }

  .skills a {
    font-size: 1.6rem;
  }

  .container svg {
    top: 0.1rem;
    height: 1.5rem;
    margin-left: 0.4rem;
  }
}

/* SMARTPHONES - TALLER SCREENS (PORTRAIT) */
@media only screen and (min-width: 390px) and (orientation: portrait) {
  /* not needed */
}

/* SMARTPHONES - TALLER SCREENS (LANDSCAPE) */
@media only screen and (min-width: 843px) and (orientation: landscape) {
  /* not needed */
}

/* IPAD MINI (PORTRAIT) */
@media only screen and (min-width: 767px) and (orientation: portrait) {
  .skills {
    padding-top: var(--padding-l);
    padding-left: var(--padding-m);
    padding-right: var(--padding-m);
    padding-bottom: var(--padding-m);
  }

  .container ul li:first-child {
    font-size: 2rem;
  }
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .container li {
    font-size: 1.8rem;
    padding-top: 1.2rem;
  }

  .skills a {
    font-size: 1.8rem;
  }

  .container svg {
    height: 1.7rem;
    margin-left: 0.4rem;
  }
}

/* IPAD MINI (LANDSCAPE) */
@media only screen and (min-width: 1023px) and (orientation: landscape) {
  .skills {
    padding-top: var(--padding-l);
    padding-left: var(--padding-m);
    padding-right: var(--padding-m);
    padding-bottom: var(--padding-m);
  }

  .container ul li:first-child {
    font-size: 2rem;
  }

  .container li {
    font-size: 1.8rem;
  }

  .skills a {
    font-size: 1.8rem;
  }

  .container svg {
    height: 1.73rem;
    margin-left: 0.4rem;
  }
}

/* IPAD AIR (PORTRAIT) */
@media only screen and (min-width: 769px) and (orientation: portrait) {
  /* not needed */
}

/* IPAD AIR (LANDSCAPE) */
@media only screen and (min-width: 1179px) and (orientation: landscape) {
  /* not needed */
}

/* IPAD PRO (PORTRAIT) */
@media only screen and (min-width: 1023px) and (orientation: portrait) {
  /* not needed */
}

/* IPAD PRO and MACBOOK PRO 14" (LANDSCAPE) */
@media only screen and (min-width: 1365px) and (orientation: landscape) {
  /* not needed */
}

/* MONITORS (LANDSCAPE) */
@media only screen and (min-width: 1520px) and (orientation: landscape) {
  /* not needed */
}

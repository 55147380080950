.about {
  z-index: 2;
  display: flex;
  position: relative;
  min-height: 100dvh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--black);
}

.container {
  max-width: var(--content-width);
}

.about span.word {
  display: inline-block;
  transition: color 0.5s ease-in-out;
}

.about span.word:hover {
  color: var(--orange);
}

.about span.word.hovered {
  color: var(--orange);
}

/* SMARTPHONES - SHORTER SCREENS (PORTRAIT) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
  .about {
    padding-top: var(--padding-l);
    padding-left: var(--padding-s);
    padding-right: var(--padding-s);
    padding-bottom: var(--padding-s);
  }

  .about span.word {
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-right: 0.6rem;
  }
}

/* SMARTPHONES - SHORTER SCREENS (LANDSCAPE) */
@media only screen and (min-width: 665px) and (orientation: landscape) {
  .about {
    padding-top: var(--padding-l);
    padding-left: var(--padding-s);
    padding-right: var(--padding-s);
    padding-bottom: var(--padding-s);
  }

  .about span.word {
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-right: 0.6rem;
  }
}

/* SMARTPHONES - TALLER SCREENS (PORTRAIT) */
@media only screen and (min-width: 390px) and (orientation: portrait) {
  .about {
    padding-top: var(--padding-l);
    padding-left: var(--padding-s);
    padding-right: var(--padding-s);
    padding-bottom: var(--padding-s);
  }

  .about span.word {
    font-size: 2.4rem;
    line-height: 3rem;
    margin-right: 0.8rem;
  }
}

/* SMARTPHONES - TALLER SCREENS (LANDSCAPE) */
@media only screen and (min-width: 843px) and (orientation: landscape) {
  .about span.word {
    font-size: 2.4rem;
    line-height: 3rem;
    margin-right: 0.8rem;
  }
}

/* IPAD MINI (PORTRAIT) */
@media only screen and (min-width: 767px) and (orientation: portrait) {
  .about {
    padding-top: var(--padding-l);
    padding-left: var(--padding-m);
    padding-right: var(--padding-m);
    padding-bottom: var(--padding-m);
  }

  .about span.word {
    font-size: 4rem;
    margin-right: 1rem;
    line-height: 5.2rem;
  }
}

/* IPAD MINI (LANDSCAPE) */
@media only screen and (min-width: 1023px) and (orientation: landscape) {
  .about span.word {
    font-size: 4rem;
    line-height: 5rem;
    margin-right: 0.8rem;
  }
}

/* IPAD AIR (PORTRAIT) */
@media only screen and (min-width: 769px) and (orientation: portrait) {
  /* not needed */
}

/* IPAD AIR (LANDSCAPE) */
@media only screen and (min-width: 1179px) and (orientation: landscape) {
  .about span.word {
    font-size: 4.4rem;
    margin-right: 1rem;
    line-height: 5.2rem;
  }
}

/* IPAD PRO (PORTRAIT) */
@media only screen and (min-width: 1023px) and (orientation: portrait) {
  .about span.word {
    font-size: 4.6rem;
    line-height: 5.8rem;
    margin-right: 1.4rem;
  }
}

/* IPAD PRO and MACBOOK PRO 14" (LANDSCAPE) */
@media only screen and (min-width: 1365px) and (orientation: landscape) {
  .about span.word {
    font-size: 5rem;
    line-height: 5.2rem;
    margin-right: 1.2rem;
  }
}

/* MONITORS (LANDSCAPE) */
@media only screen and (min-width: 1520px) and (orientation: landscape) {
  .about span.word {
    font-size: 4.4rem;
    line-height: 5.2rem;
    margin-right: 1.2rem;
  }
}

.hero {
  z-index: 2;
  display: flex;
  min-width: 100vw;
  min-height: 100dvh;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
}

.hero__text {
  display: flex;
  min-width: 100vw;
  min-height: 100dvh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.hero__text h1 {
  z-index: 2;
  width: 100%;
  text-align: center;
  color: var(--orange);
  font-weight: var(--regular-400);
  font-family: 'Dr Sugiyama', cursive;
}

.hero__text p {
  z-index: 4;
  width: 100%;
  text-align: center;
  color: var(--gray-lighter);
  font-weight: var(--thin-100);
}

.img__container {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}

.img__back {
  width: 60%;
  z-index: 1;
}

.img__front {
  width: 60%;
  z-index: 3;
}

/* SMARTPHONES - SHORTER SCREENS (PORTRAIT) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
  .hero__text h1 {
    padding-left: 2%;
    font-size: 8.5rem;
    line-height: 5.3rem;
  }

  .hero__text p {
    font-size: 1.6rem;
  }

  .img__back {
    width: 60%;
  }

  .img__front {
    width: 60%;
  }
}

/* SMARTPHONES - SHORTER SCREENS (LANDSCAPE) */
@media only screen and (min-width: 665px) and (orientation: landscape) {
  .hero__text h1 {
    font-size: 11rem;
    line-height: 8rem;
  }

  .hero__text p {
    font-size: 2rem;
  }

  .img__back {
    width: 30%;
  }

  .img__front {
    width: 30%;
  }
}

/* SMARTPHONES - TALLER SCREENS (PORTRAIT) */
@media only screen and (min-width: 390px) and (orientation: portrait) {
  .hero__text h1 {
    font-size: 9rem;
    line-height: 6rem;
  }

  .hero__text p {
    font-size: 1.6rem;
  }

  .img__back {
    width: 55%;
  }

  .img__front {
    width: 55%;
  }
}

/* SMARTPHONES - TALLER SCREENS (LANDSCAPE) */
@media only screen and (min-width: 843px) and (orientation: landscape) {
  /* not needed */
}

/* IPAD MINI (PORTRAIT) */
@media only screen and (min-width: 767px) and (orientation: portrait) {
  .hero__text h1 {
    font-size: 16.5rem;
    line-height: 11rem;
  }

  .hero__text p {
    font-size: 3rem;
  }

  .img__back {
    width: 60%;
  }

  .img__front {
    width: 60%;
  }
}

/* IPAD MINI (LANDSCAPE) */
@media only screen and (min-width: 1023px) and (orientation: landscape) {
  .hero__text h1 {
    font-size: 20rem;
    line-height: 16rem;
  }

  .hero__text p {
    font-size: 3.4rem;
  }

  .img__back {
    width: 50%;
  }

  .img__front {
    width: 50%;
  }
}

/* IPAD AIR (PORTRAIT) */
@media only screen and (min-width: 769px) and (orientation: portrait) {
  .hero__text h1 {
    font-size: 18rem;
    padding-left: 2%;
    line-height: 12rem;
  }

  .hero__text p {
    font-size: 3.2rem;
  }

  .img__back {
    width: 60%;
  }

  .img__front {
    width: 60%;
  }
}

/* IPAD AIR (LANDSCAPE) */
@media only screen and (min-width: 1179px) and (orientation: landscape) {
  .hero__text h1 {
    font-size: 22rem;
    line-height: 14rem;
  }

  .hero__text p {
    font-size: 3.6rem;
  }
}

/* IPAD PRO (PORTRAIT) */
@media only screen and (min-width: 1023px) and (orientation: portrait) {
  .hero__text h1 {
    font-size: 22rem;
    line-height: 16rem;
  }

  .hero__text p {
    font-size: 3.8rem;
  }

  .img__back {
    width: 60%;
  }

  .img__front {
    width: 60%;
  }
}

/* MACBOOK PRO 14" and IPAD PRO  (LANDSCAPE) */
@media only screen and (min-width: 1365px) and (orientation: landscape) {
  .hero__text h1 {
    font-size: 28rem;
    line-height: 19rem;
  }

  .hero__text p {
    font-size: 4.4rem;
  }

  .img__back {
    width: 60%;
  }

  .img__front {
    width: 60%;
  }
}

/* MONITORS (LANDSCAPE) */
@media only screen and (min-width: 1520px) and (orientation: landscape) {
  /* .hero__text h1 {
    font-size: 28rem;
    line-height: 19rem;
  }

  .hero__text p {
    font-size: 4.4rem;
  } */
}

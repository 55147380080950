footer {
  bottom: 0;
  z-index: 1;
  position: fixed;
}

.contact {
  width: 100vw;
  height: 100dvh;
  background-color: var(--gray-lighter);
}

.contact__tm {
  cursor: pointer;
  color: var(--orange);
  text-transform: uppercase;
  font-family: 'Dr Sugiyama', cursive;
}

.contact__text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.text__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.text__container h2 {
  z-index: 1;
  font-weight: var(--semiBold-600);
}

.text__container p {
  display: flex;
  justify-content: flex-end;
  font-weight: var(--thin-100);
}

.contact__links {
  z-index: 1;
  padding-bottom: 10%;
}

.contact__links a {
  cursor: pointer;
  text-decoration: none;
  transition: color 1s ease;
  color: var(--gray-darker);
  font-weight: var(--light-300);
}

.contact__links a:hover {
  color: var(--orange);
}

.copyright {
  display: flex;
  text-align: right;
  flex-direction: column;
  justify-content: flex-end;
}

.copyright p {
  color: var(--gray-darker);
  font-weight: var(--thin-100);
}

.copyright span {
  color: var(--gray-darker);
}

/* SMARTPHONES - SHORTER SCREENS (PORTRAIT) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
  .contact {
    padding-top: var(--padding-l);
    padding-left: var(--padding-s);
    padding-right: var(--padding-s);
    padding-bottom: var(--padding-s);
  }

  .contact h2 {
    font-size: 5rem;
    line-height: 3.6rem;
  }

  .text__container {
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .text__container p:first-of-type {
    z-index: 2;
    width: 100%;
    font-size: 1.4rem;
    line-height: 1.4rem;
    margin-bottom: 16rem;
    justify-content: flex-start;
  }

  .contact__tm {
    left: 6%;
    top: 22rem;
    font-size: 20rem;
    line-height: 10rem;
    position: relative;
  }

  .contact__links {
    margin-left: 0.2rem;
  }

  .contact__links a {
    font-size: 1.4rem;
    padding-right: 1.5rem;
  }

  .contact__text .copyright p {
    font-size: 0.8rem;
  }

  .contact__text .copyright span {
    font-size: 0.8rem;
  }
}

/* SMARTPHONES - SHORTER SCREENS (LANDSCAPE) */
@media only screen and (min-width: 665px) and (orientation: landscape) {
  .contact {
    padding-top: var(--padding-l);
    padding-left: var(--padding-s);
    padding-right: var(--padding-s);
    padding-bottom: var(--padding-s);
  }

  .contact h2 {
    font-size: 6rem;
    line-height: 4.5rem;
  }

  .contact__tm {
    font-size: 20rem;
    padding-left: 4%;
    line-height: 10rem;
  }

  .text__container p {
    z-index: 2;
    width: 50%;
    font-size: 1.1rem;
    padding-right: 5%;
  }

  .contact__links {
    padding-bottom: 2%;
    margin-left: 0.2rem;
  }

  .contact__links a {
    font-size: 1.4rem;
    padding-right: 1.5rem;
  }

  .contact__text .copyright p {
    font-size: 0.8rem;
  }

  .contact__text .copyright span {
    font-size: 0.8rem;
  }
}

/* SMARTPHONES - TALLER SCREENS (PORTRAIT) */
@media only screen and (min-width: 390px) and (orientation: portrait) {
  /* not needed */
}

/* SMARTPHONES - TALLER SCREENS (LANDSCAPE) */
@media only screen and (min-width: 843px) and (orientation: landscape) {
  /* not needed */
}

/* IPAD MINI (PORTRAIT) */
@media only screen and (min-width: 767px) and (orientation: portrait) {
  .contact {
    padding-top: var(--padding-l);
    padding-left: var(--padding-m);
    padding-right: var(--padding-m);
    padding-bottom: var(--padding-m);
  }

  .contact h2 {
    font-size: 12rem;
    line-height: 9rem;
  }

  .text__container {
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .text__container p {
    z-index: 2;
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 26rem;
    justify-content: flex-start;
  }

  .contact__tm {
    left: 6%;
    top: 33rem;
    font-size: 30rem;
    line-height: 8rem;
    width: fit-content;
    position: relative;
  }

  .contact__links {
    margin-left: 0.2rem;
  }

  .contact__links a {
    font-size: 1.6rem;
    padding-right: 1.5rem;
  }

  .contact__text .copyright p {
    font-size: 1rem;
  }

  .contact__text .copyright span {
    font-size: 1rem;
  }
}

/* IPAD MINI (LANDSCAPE) */
@media only screen and (min-width: 1023px) and (orientation: landscape) {
  .contact {
    padding-top: var(--padding-l);
    padding-left: var(--padding-m);
    padding-right: var(--padding-m);
    padding-bottom: var(--padding-m);
  }

  .contact h2 {
    font-size: 12rem;
    line-height: 9rem;
  }

  .text__container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .text__container p {
    z-index: 2;
    width: 100%;
    margin-bottom: 0;
    font-size: 1.8rem;
    padding-right: 3%;
    line-height: 2.1rem;
    justify-content: flex-end;
    justify-content: flex-start;
  }

  .contact__tm {
    top: 0;
    left: 2%;
    font-size: 30rem;
    line-height: 8rem;
    width: fit-content;
    position: relative;
  }

  .contact__links {
    padding-bottom: 8%;
    margin-left: 0.2rem;
  }

  .contact__links a {
    font-size: 1.6rem;
    padding-right: 1.5rem;
  }

  .contact__text .copyright p {
    font-size: 1rem;
  }

  .contact__text .copyright span {
    font-size: 1rem;
  }
}

/* IPAD AIR (PORTRAIT) */
@media only screen and (min-width: 769px) and (orientation: portrait) {
  .contact {
    display: flex;
    justify-content: center;
  }

  .contact__tm {
    top: 0;
    left: 5%;
  }

  .contact__text {
    min-width: 100%;
  }

  .text__container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .text__container p {
    margin-bottom: 0;
    padding-right: 3%;
    justify-content: flex-end;
  }
}

/* IPAD AIR (LANDSCAPE) */
@media only screen and (min-width: 1179px) and (orientation: landscape) {
  .contact {
    display: flex;
    justify-content: center;
  }

  .contact__tm {
    top: 0;
    left: 1%;
  }

  .contact__text {
    min-width: var(--content-width);
  }

  .text__container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .text__container p {
    margin-bottom: 0;
    padding-right: 3%;
    justify-content: flex-end;
  }

  .contact__links {
    padding-bottom: 10%;
  }
}

/* IPAD PRO (PORTRAIT) */
@media only screen and (min-width: 1023px) and (orientation: portrait) {
  /* not needed */
}

/* IPAD PRO and MACBOOK PRO 14" (LANDSCAPE) */
@media only screen and (min-width: 1365px) and (orientation: landscape) {
  /* not needed */
}

/* MONITORS (LANDSCAPE) */
@media only screen and (min-width: 1520px) and (orientation: landscape) {
  /* not needed */
}

:root {
  --gray-dark: #b6b7b2;
  --gray-darker: #9b9b9b;
  --gray-lighter: #eff1ed;
  --gray-darker-inactive: rgba(182, 183, 179, 0.5);
  --gray-navbar-background: rgb(228 228 228 / 45%);

  --black: #252525;
  --orange: #ffb270;

  --thin-100: 100;
  --light-300: 300;
  --regular-400: 400;
  --semiBold-600: 600;

  --inter: 'Inter', sans-serif;
  --sugiyama: 'Dr Sugiyama', cursive;

  --padding-s: 2rem;
  --padding-m: 3rem;
  --padding-l: 6rem;
  --content-width: 1024px;
}

html {
  width: 100vw;
  height: 100vh;
  cursor: default;
  user-select: none;
  overflow-y: scroll;
  color: var(--black);
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory; /* Standard property */
  background-color: var(--black);
  -ms-scroll-snap-type: y mandatory; /* IE 10 and 11 */
  -webkit-scroll-snap-type: y mandatory; /* Safari, Chrome, and newer versions of Opera */
}

section {
  scroll-snap-align: center; /* Standard property */
  -ms-scroll-snap-align: unset; /* IE 10 and 11 */
  -webkit-scroll-snap-align: unset; /* Safari, Chrome, and newer versions of Opera */
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  font-size: 62.5%; /* base font size to 10px, to facilitate using REM unit */
  box-sizing: border-box;
  font-family: var(--inter);
}

::-webkit-scrollbar {
  display: none;
}

/* SMARTPHONES - SHORTER SCREENS (PORTRAIT) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
  section,
  footer {
    scroll-snap-align: unset; /* Standard property */
    -ms-scroll-snap-align: unset; /* IE 10 and 11 */
    -webkit-scroll-snap-align: unset; /* Safari, Chrome, and newer versions of Opera */
  }
}

/* SMARTPHONES - SHORTER SCREENS (LANDSCAPE) */
@media only screen and (min-width: 665px) and (orientation: landscape) {
  section,
  footer {
    scroll-snap-align: unset; /* Standard property */
    -ms-scroll-snap-align: unset; /* IE 10 and 11 */
    -webkit-scroll-snap-align: unset; /* Safari, Chrome, and newer versions of Opera */
    /* -ms-scroll-chaining: none; */
  }
}

/* IPAD MINI (PORTRAIT) */
@media only screen and (min-width: 767px) and (orientation: portrait) {
  section,
  footer {
    scroll-snap-align: unset; /* Standard property */
    -ms-scroll-snap-align: unset; /* IE 10 and 11 */
    -webkit-scroll-snap-align: unset; /* Safari, Chrome, and newer versions of Opera */
    /* -ms-scroll-chaining: none; */
  }
}

/* IPAD MINI (LANDSCAPE) */
@media only screen and (min-width: 1023px) and (orientation: landscape) {
  section,
  footer {
    scroll-snap-align: unset; /* Standard property */
    -ms-scroll-snap-align: unset; /* IE 10 and 11 */
    -webkit-scroll-snap-align: unset; /* Safari, Chrome, and newer versions of Opera */
  }
}

/* IPAD AIR (LANDSCAPE) */
@media only screen and (min-width: 1179px) and (orientation: landscape) {
  section,
  footer {
    scroll-snap-align: center; /* Standard property */
    -ms-scroll-snap-align: unset; /* IE 10 and 11 */
    -webkit-scroll-snap-align: unset; /* Safari, Chrome, and newer versions of Opera */
  }
}
